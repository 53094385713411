import React from "react";
import { SectionWhyLIMS } from "../SectionWhyLims";
import STAR_ORANGE from "@assets/icons/Lims/star-orange.svg";
import { StaticImage } from "gatsby-plugin-image";
import STAR_BLUE_1 from "@assets/icons/Lims/star-blue-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./LabEquipment.module.scss";
import { WhyLimsProps } from "../SectionWhyLims/data";
import { useWindowSizeType } from "@helpers/Hooks";

export const LabEquipment = () => {
	const windowSizeType = useWindowSizeType();

	const props: WhyLimsProps = {
		title: "Lab equipment management",
		starOnTitle: <SVG src={STAR_BLUE_1} className={styles.starBlue1} />,
		description:
			"Streamline lab equipment management for smoother operations. Experience seamless scheduling, booking, and maintenance of lab equipment.",
		list: [
			{
				text: "Visualized lab instrument availability and scheduling",
				icon: STAR_ORANGE,
				id: 1,
			},
			{
				text: "Comprehensive lab equipment management",
				icon: STAR_ORANGE,
				id: 2,
			},

			{
				text: "User-intuitive booking experience",
				icon: STAR_ORANGE,
				id: 3,
			},
		],
		link: "EQUIPMENT",
		image: (
			<div className={styles.imageWrapper}>
				<StaticImage
					loading="eager"
					src={"../../../assets/images/lims/equipment-product.png"}
					alt="Equipment Product"
				/>
			</div>
		),
		reverse:
			windowSizeType === "mobile"
				? false
				: true,
		gap: "gapLg",
		sectionWrapperOverwrite: styles.sectionWrapperOverwrite,
	};
	return <SectionWhyLIMS {...props} />;
};
