import React from "react";
import { SectionWhyLIMS } from "../SectionWhyLims";
import STAR_ORANGE from "@assets/icons/Lims/star-orange.svg";
import { StaticImage } from "gatsby-plugin-image";
import RECTANGLE_2 from "@assets/icons/Lims/rectangle-blue-2.svg";
import RECTANGLE_1 from "@assets/icons/Lims/rectangle-blue-1.svg";
import STAR_BLUE_1 from "@assets/icons/Lims/star-blue-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./VirtualLab.module.scss";
import { WhyLimsProps } from "../SectionWhyLims/data";

export const VirtualLab = () => {
	const props: WhyLimsProps = {
		title: "Sample management software with virtual lab freezer",
		starOnTitle: <SVG src={STAR_BLUE_1} className={styles.starBlue1} />,
		description:
			"Genemod's LIMS replicates your real-world freezer with a complete, at-a-glance view. Users can now manage, track, and record all the items effortlessly.",
		list: [
			{
				text: "A fresh take on sample storage",
				icon: STAR_ORANGE,
				id: 1,
			},
			{
				text: "Seamless management of samples",
				icon: STAR_ORANGE,
				id: 2,
			},

			{
				text: "Comprehensive search tools",
				icon: STAR_ORANGE,
				id: 3,
			},
		],
		link: "INVENTORY",
		image: (
			<div className={styles.imageWrapper}>
				<SVG src={RECTANGLE_2} className={styles.rectangle2} />
				<StaticImage
					loading="eager"
					src={"../../../assets/images/lims/freezer.png"}
					alt="Virtual Lab Freezer"
				/>
				<SVG src={RECTANGLE_1} className={styles.rectangle1} />
			</div>
		),
		reverse: true,
	};
	return <SectionWhyLIMS {...props} />;
};
