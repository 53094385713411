import { PageLayout, TypographyV2 } from "@components/index";
import { AllInOne } from "@components/LimsSoftware/AllInOne";
import { Hero } from "@components/LimsSoftware/Hero";
import { LabEquipment } from "@components/LimsSoftware/LapEquipment";
import { LIMS } from "@components/LimsSoftware/Lims";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import { StockTracker } from "@components/LimsSoftware/StockTracker";
import { VirtualLab } from "@components/LimsSoftware/VirtualLab";
import React from "react";
import * as styles from "./index.module.scss";
import { SectionLearnMore } from "@components/LimsSoftware/SectionLearnMore";
import { FeatureSelectorChanger } from "pages/ads/_components/_Features/_Features";
import { Industries } from "pages/ads/_components/_Industries/_Industries";
import JoinLeadersTestimonials from "@components/JoinLeadersTestimonials/JoinLeadersTestimonials";
import { Faqs } from "@components/LimsSoftware/Faqs";
import GENEMOD_FREEZER from "@assets/products/genemod-intro-video.mp4";

export default function LIMSSoftwarePage() {
	const joinLeadersProps = {
		joinInstitutionBodyClass: styles.joinInstitutionBodyClass,
		inputWrapperOverwrite: styles.inputWrapperOverwrite,
		testimonialOverwrite: styles.testimonialOverwrite,
	};
	const industriesSectionProps = {
		tabsGap: styles.tabsGap,
		industriesWrapper: styles.industriesWrapper,
		overrideFirstImage: styles.overrideFirstImage,
		overrideSecondImage: styles.overrideSecondImage,
		overrideImagesCard: styles.overrideImagesCard,
		justTitleAndContentOverwrite: styles.justTitleAndContentOverwrite,
	};
	const featureSelectorProps = {
		featureSelectorChangerOverwrite: styles.featureSelectorChangerOverwrite,
	};
	const customerQuotes = [
		{
			quote: `“User-friendly interface, powerful features, and commitment to security and compliance make it a standout choice in the competitive landscape of laboratory software.”`,
			author: "Bhavya V.",
			authorTitle: "Research Scientist",
		},
		{
			quote: `“My favorite aspect of Genemod is the dynamic inventory storage system which enables you to walk through freezers and boxes digitally.”`,
			author: "Christopher O.",
			authorTitle: "Research Scientist",
		},
		{
			quote: `“Genemod allows our company to manage inventory data end-to-end in one single platform. It is very easy to use and has greatly improved our efficiency.”`,
			author: "Ryan K.",
			authorTitle: "Sr. Scientist",
		},
	];
	const faqs = [
		{
			question:
				"What is laboratory information management systems (LIMS)?",
			children: [
				`Laboratory Information Management Systems (LIMS) are sophisticated software applications designed to streamline laboratory operations by managing data, automating workflows, and ensuring compliance with regulatory standards.`,
			],
			id: "1",
		},
		{
			question: "What is electronic lab notebook (ELN)?",
			children: [
				`Electronic Lab Notebooks (ELN) digitize the traditional lab notebook, offering an organized, searchable, and secure way to document experiments. ELN software not only captures your research with precision but also enhances collaboration across your team, bringing a new level of innovation and productivity to your lab's workbench.`,
			],
			id: "2",
		},
		{
			question: "How can LIMS and ELN benefit my research?",
			children: [
				`The integration of LIMS and ELN facilitates a seamless research workflow, reducing manual errors, improving data traceability, and saving valuable time. With comprehensive LIMS and ELN solutions, researchers can focus on discovery and innovation, knowing that their data management is in expert hands.`,
			],
			id: "3",
		},
		{
			question:
				"What types of laboratories can benefit from using LIMS software?",
			children: [
				`Laboratories involved in scientific research can effectively utilize LIMS software. These include biopharmaceutical, industrial biotech, clinical research, biomanufacturing, biorepositories, contract services, and more. Labs of all sizes, from small startups to large enterprises, can utilize LIMS to enhance their operations, improve data management, streamline workflows, and ensure regulatory compliance.`,
			],
			id: "4",
		},
		{
			question:
				"What is the difference between cloud-based LIMS and legacy LIMS software?",
			children: [
				`Cloud-based LIMS offers convenient accessibility from any location, improving collaboration and productivity. It scales easily with the growing needs of the lab, and data storage and security are managed by the provider, ensuring higher levels of data protection and regular backups. In contrast, legacy LIMS often requires significant on-premise infrastructure, higher upfront investment, and dedicated IT support for maintenance and updates. Collaboration can also be limited to the physical location of the legacy system, making cloud-based solutions more flexible and efficient for modern labs.`,
			],
			id: "5",
		},
		{
			question: "What advantage does Genemod’s LIMS software offer?",
			children: [
				`Genemod's LIMS is engineered to be the central hub for
			laboratory management, integrating various
			functionalities such as inventory tracking, electronic
			lab notebooks, equipment scheduling, order management,
			and many other scientific research features into a
			unified platform. This integration facilitates a
			seamless, efficient workflow, enabling scientists to
			concentrate on their core research objectives with fewer
			administrative burdens.`,
				`	Key benefits include:`,
				<ul>
					<li>
						<b>Streamlined operations: </b>Our platform automates
						and simplifies routine tasks, allowing for more focused
						research time and less administrative overhead.
					</li>
					<li>
						<b>Budget optimization: </b>The all-in-one nature of
						Genemod’s LIMS reduces the need for multiple software
						systems, which can translate into considerable cost
						savings.
					</li>
					<li>
						<b>Collaborative environment:</b>Real-time data sharing
						and management features promote effective team
						collaboration, regardless of physical location.
					</li>
					<li>
						<b>Data integrity and compliance:</b> Maintain high data
						quality with secure, compliant, and traceable data
						management capabilities.
					</li>
					<li>
						<b>Operational oversight:</b> Gain insights into your
						lab's productivity with advanced analytics and
						reporting, supporting strategic decisions and continuous
						improvement.
					</li>
				</ul>,
			],
			id: "6",
		},
		{
			question:
				"Can Genemod’s LIMS software be customized for my specific needs?",
			children: [
				`Absolutely. At Genemod, we understand that each laboratory's needs are unique. Our flexible LIMS platforms are designed to adapt to your specific research requirements, offering customizability that ensures a perfect fit for your workflow.`,
			],
			id: "7",
		},
		{
			question:
				"What distinguishes Genemod’s LIMS & ELN from other solutions on the market?",
			children: [
				`		Genemod's LIMS stands out for their intuitive
			integration, scalability, and personalized support.
			While other systems manage data and workflows, Genemod
			goes further by providing a seamless, user-friendly
			experience that adapts to your lab's evolving needs`,
				`Distinctive features include:`,
				<ul>
					<li>
						<b>Dynamic LIMS products:</b>
						Genemod LIMS is a comprehensive platform with virtual
						freezers, consumable management, order management,
						electronic lab notebook (ELN), lab equipment scheduler,
						and many other features for scientific research and lab
						management. 
					</li>
					<li>
						<b>Intuitive design:</b> Genemod LIMS platform is
						created with the user experience at the forefront,
						ensuring that scientists can intuitively navigate and
						utilize the system with minimal training.
					</li>
					<li>
						<b>Advanced integration:</b>
						Genemod offers a truly interconnected system where data
						flows effortlessly between LIMS and ELN, providing a
						cohesive ecosystem for all your laboratory data
						management needs.
					</li>
					<li>
						<b>Customizable framework:</b>
						Genemod is highly customizable to align with your
						specific research processes and goals.
					</li>
					<li>
						<b>Scalable architecture: </b>
						Genemod is built to scale with your laboratory's growth,
						ensuring you have a robust system at every stage of your
						expansion.
					</li>
					<li>
						<b>Dedicated support:</b>
						Our global team of scientists provide unmatched support,
						bringing their expertise directly to your lab's unique
						challenges.
					</li>
				</ul>,
			],

			id: "8",
		},
	];
	const heroProps = {
		sub_title: "Products",
		title: "Advanced cloud-based LIMS software for research labs",
		video_src: GENEMOD_FREEZER,
		text: "Transform your laboratory's productivity with Genemod's advanced LIMS. Enhance the management of samples, experiments, equipment, and all essential lab components, driving operational excellence and delivering significant returns on investment through increased efficiency and cost savings.",
		controls: true,
	};
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "lims-software" }}
			defaultHeader="light"
		>
			<div className={styles.pageWrapper}>
				<Hero {...heroProps} />
				<div className={styles.featureSelectorContainer}>
					<FeatureSelectorChanger {...featureSelectorProps} />
				</div>
				<LIMS />
				<VirtualLab />
				<StockTracker />
				<div className={styles.ourPartners}>
					<PartnersV2 />
				</div>
				<LabEquipment />
				<AllInOne />
				<Industries {...industriesSectionProps} />
				<SectionLearnMore />
				<JoinLeadersTestimonials
					testimonials={customerQuotes}
					{...joinLeadersProps}
				/>
				<Faqs title="Frequently asked questions" faqs={faqs} />
			</div>
		</PageLayout>
	);
}
