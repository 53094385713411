import React from "react";
import { SectionWhyLIMS } from "../SectionWhyLims";
import STAR from "@assets/icons/Lims/star.svg";
import { StaticImage } from "gatsby-plugin-image";
import RECTANGLE_3 from "@assets/icons/Lims/rectangle-3.svg";
import RECTANGLE_1 from "@assets/icons/Lims/rectangle-1.svg";
import STAR_GRAY_1 from "@assets/icons/Lims/star-gray-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./Lims.module.scss";
import { WhyLimsProps } from "../SectionWhyLims/data";

export const LIMS = () => {
	const props: WhyLimsProps = {
		title: "Laboratory Information Management System (LIMS)",
		starOnTitle: <SVG src={STAR_GRAY_1} className={styles.starGray1} />,
		description:
			"Genemod's comprehensive Laboratory Information Management Systems (LIMS) and Electronic Lab Notebook (ELN) transform lab operations into streamlined, efficient workflows.",
		list: [
			{
				text: "3–4.5x in return of investment",
				icon: STAR,
				id: 1,
			},
			{
				text: "85% reduction on repetitive tasks",
				icon: STAR,
				id: 2,
			},

			{
				text: "90% reduction in human error in data recording",
				icon: STAR,
				id: 3,
			},
			{
				text: "70% reduction in lead time",
				icon: STAR,
				id: 4,
			},
		],
		link: "LIMS",
		image: (
			<div className={styles.imageWrapper}>
				<SVG src={RECTANGLE_3} className={styles.rectangle3} />
				<StaticImage
					loading="eager"
					src={"../../../assets/images/lims/notebook.png"}
					alt="Basic Protocols"
				/>
				<SVG src={RECTANGLE_1} className={styles.rectangle1} />
			</div>
		),
	};
	return <SectionWhyLIMS {...props} />;
};
